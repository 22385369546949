const branchIO = {
  methods: {
    loginBranchEvent(branchData) {
      if (branchData === undefined && typeof branchData !== "object") return;

      let data = {};

      if (branchData.hasOwnProperty("Subscriber_ID") === true) {
        data.Subscriber_ID = branchData.Subscriber_ID;
      }
      if (branchData.hasOwnProperty("Name") === true) {
        data.Name = branchData.Name;
      }
      if (branchData.hasOwnProperty("Gender") === true) {
        data.Gender = branchData.Gender;
      }
      if (branchData.hasOwnProperty("Email") === true) {
        data.Email = branchData.Email;
      }
      if (branchData.hasOwnProperty("Phone") === true) {
        data.Phone = branchData.Phone;
      }
      if (branchData.hasOwnProperty("DOB") === true) {
        data.DOB = branchData.DOB;
      }
      if (branchData.hasOwnProperty("Age") === true) {
        data.Age = branchData.Age;
      }
      if (branchData.hasOwnProperty("Country") === true) {
        data.Country = branchData.Country;
      }

      console.log("branch_LOGIN", data);
      branch?.logEvent("LOGIN", data, function(err) {
        console.log(err);
      });
    },

    signupBranchEvent(branchData) {
      if (branchData === undefined && typeof branchData !== "object") return;

      let data = {};

      if (branchData.hasOwnProperty("Sign_Up_Mode") === true) {
        data.Sign_Up_Mode = branchData.Sign_Up_Mode;
      }
      if (branchData.hasOwnProperty("Status") === true) {
        data.Status = branchData.Status;
      }
      if (branchData.hasOwnProperty("Subscriber_ID") === true) {
        data.Subscriber_ID = branchData.Subscriber_ID;
      }
      if (branchData.hasOwnProperty("Error_Reason") === true) {
        data.Error_Reason = branchData.Error_Reason;
      }

      console.log("branch_COMPLETE_REGISTRATION", data);
      branch?.logEvent("COMPLETE_REGISTRATION", data, function(err) {
        console.log(err);
      });
    },

    branchInitiateStreamEvent(branchData) {
      if (branchData === undefined && typeof branchData !== "object") return;

      let data = {};

      if (branchData.hasOwnProperty("Content_ID") === true) {
        data.Content_ID = branchData.Content_ID;
      }
      if (branchData.hasOwnProperty("Content_Title") === true) {
        data.Content_Title = branchData.Content_Title;
      }
      if (branchData.hasOwnProperty("Content_Type") === true) {
        data.Content_Type = branchData.Content_Type;
      }
      if (branchData.hasOwnProperty("Content_Genre") === true) {
        data.Content_Genre = branchData.Content_Genre;
      }
      if (branchData.hasOwnProperty("Content_Language") === true) {
        data.Content_Language = branchData.Content_Language;
      }
      if (branchData.hasOwnProperty("Watch_Time") === true) {
        data.Watch_Time = branchData.Watch_Time;
      }
      if (branchData.hasOwnProperty("Content_Duration") === true) {
        data.Content_Duration = branchData.Content_Duration;
      }
      if (branchData.hasOwnProperty("Playback_Type") === true) {
        data.Playback_Type = branchData.Playback_Type;
      }
      if (branchData.hasOwnProperty("Streamed_Upto_25perc") === true) {
        data.Streamed_Upto_25perc = branchData.Streamed_Upto_25perc;
      }
      if (branchData.hasOwnProperty("Streamed_Upto_50perc") === true) {
        data.Streamed_Upto_50perc = branchData.Streamed_Upto_50perc;
      }
      if (branchData.hasOwnProperty("Streamed_Upto_75perc") === true) {
        data.Streamed_Upto_75perc = branchData.Streamed_Upto_75perc;
      }
      if (branchData.hasOwnProperty("Network_Type") === true) {
        data.Network_Type = branchData.Network_Type;
      }
      if (branchData.hasOwnProperty("Finished_Watching") === true) {
        data.Finished_Watching = branchData.Finished_Watching;
      }
      if (branchData.hasOwnProperty("Start_Position") === true) {
        data.Start_Position = branchData.Start_Position;
      }

      console.log("branch_STREAM event", data);
      branch?.logEvent("INITIATE_STREAM", data, function(err) {
        console.log(err);
      });
    },

    branchCompleteStreamEvent(branchData) {
      if (branchData === undefined && typeof branchData !== "object") return;

      let data = {};

      if (branchData.hasOwnProperty("Content_ID") === true) {
        data.Content_ID = branchData.Content_ID;
      }
      if (branchData.hasOwnProperty("Content_Title") === true) {
        data.Content_Title = branchData.Content_Title;
      }
      if (branchData.hasOwnProperty("Content_Type") === true) {
        data.Content_Type = branchData.Content_Type;
      }
      if (branchData.hasOwnProperty("Content_Genre") === true) {
        data.Content_Genre = branchData.Content_Genre;
      }
      if (branchData.hasOwnProperty("Content_Language") === true) {
        data.Content_Language = branchData.Content_Language;
      }
      if (branchData.hasOwnProperty("Watch_Time") === true) {
        data.Watch_Time = branchData.Watch_Time;
      }
      if (branchData.hasOwnProperty("Content_Duration") === true) {
        data.Content_Duration = branchData.Content_Duration;
      }
      if (branchData.hasOwnProperty("Playback_Type") === true) {
        data.Playback_Type = branchData.Playback_Type;
      }
      if (branchData.hasOwnProperty("Streamed_Upto_25perc") === true) {
        data.Streamed_Upto_25perc = branchData.Streamed_Upto_25perc;
      }
      if (branchData.hasOwnProperty("Streamed_Upto_50perc") === true) {
        data.Streamed_Upto_50perc = branchData.Streamed_Upto_50perc;
      }
      if (branchData.hasOwnProperty("Streamed_Upto_75perc") === true) {
        data.Streamed_Upto_75perc = branchData.Streamed_Upto_75perc;
      }
      if (branchData.hasOwnProperty("Network_Type") === true) {
        data.Network_Type = branchData.Network_Type;
      }
      if (branchData.hasOwnProperty("Finished_Watching") === true) {
        data.Finished_Watching = branchData.Finished_Watching;
      }
      if (branchData.hasOwnProperty("Start_Position") === true) {
        data.Start_Position = branchData.Start_Position;
      }

      console.log("branch_COMPLETE_STREAM event", data);
      branch?.logEvent("COMPLETE_STREAM", data, function(err) {
        console.log(err);
      });
    },

    purchaseSubscribeBranchEvent(branchData) {
      if (branchData === undefined && typeof branchData !== "object") return;

      let data = {};

      if (branchData.hasOwnProperty("Payment_Mode") === true) {
        data.Payment_Mode = branchData.Payment_Mode;
      }
      if (branchData.hasOwnProperty("Subscription_Start_Date") === true) {
        data.Subscription_Start_Date = branchData.Subscription_Start_Date;
      }
      if (branchData.hasOwnProperty("Subscription_End_Date") === true) {
        data.Subscription_End_Date = branchData.Subscription_End_Date;
      }
      if (branchData.hasOwnProperty("Transaction_ID") === true) {
        data.Transaction_ID = branchData.Transaction_ID;
      }
      if (branchData.hasOwnProperty("Amount") === true) {
        data.Amount = branchData.Amount;
      }
      if (branchData.hasOwnProperty("Currency_Code") === true) {
        data.Currency_Code = branchData.Currency_Code;
      }
      if (branchData.hasOwnProperty("Payment_ID") === true) {
        data.Payment_ID = branchData.Payment_ID;
      }
      if (branchData.hasOwnProperty("Promo_Code") === true) {
        data.Promo_Code = branchData.Promo_Code;
      }
      if (branchData.hasOwnProperty("Discount_Amount") === true) {
        data.Discount_Amount = branchData.Discount_Amount;
      }
      if (branchData.hasOwnProperty("Free_Trial") === true) {
        data.Free_Trial = branchData.Free_Trial;
      }
      if (branchData.hasOwnProperty("Plan_ID") === true) {
        data.Plan_ID = branchData.Plan_ID;
      }
      if (branchData.hasOwnProperty("Plan_Name") === true) {
        data.Plan_Name = branchData.Plan_Name;
      }
      if (branchData.hasOwnProperty("Object_ID") === true) {
        data.Object_ID = branchData.Object_ID;
      }
      if (branchData.hasOwnProperty("Object_Name") === true) {
        data.Object_Name = branchData.Object_Name;
      }
      if (branchData.hasOwnProperty("Object_Quality") === true) {
        data.Object_Quality = branchData.Object_Quality;
      }

      console.log("branch SUBSCRIBE payment", data);
      branch?.logEvent("SUBSCRIBE", data, function(err) {
        console.log(err);
      });
    },
    purchaseInitiateBranchEvent(branchData) {
      if (branchData === undefined && typeof branchData !== "object") return;

      let data = {};
      if (branchData.hasOwnProperty("Free_Trial") === true) {
        data.Free_Trial = branchData.Free_Trial;
      }
      if (branchData.hasOwnProperty("Payment_Mode") === true) {
        data.Payment_Mode = branchData.Payment_Mode;
      }
      if (branchData.hasOwnProperty("Object_ID") === true) {
        data.Object_ID = branchData.Object_ID;
      }
      if (branchData.hasOwnProperty("Object_Name") === true) {
        data.Object_Name = branchData.Object_Name;
      }
      console.log("branch purchase initiate payment", data);
      branch?.logEvent("INITIATE_PURCHASE", data, function(err) {
        console.log(err);
      });
    }
  }
};
export default branchIO;
